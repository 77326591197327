import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Photos/logo.png'
import { Nav } from 'react-bootstrap';

/**
 * Funciona como uma nav bar colada ao topo
 * da web app, quando é feito o resize para telemovel
 * fica com um menu de opções.
 * 
 * Ao clicar em qualquer um dos links é redirecionado
 * para o local da pagina onde esta uma div com esse id.
 */
function Header() {
  return (
    <>
      <Navbar collapseOnSelect expand='sm' bg="dark" variant="dark" sticky="top" style={{backgroundColor: "#2b2d30"}}>
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            AVANCA GARE
          </Navbar.Brand>
          <Navbar.Toggle  />
          <Navbar.Collapse id='responsive-nav-bar'>
            <Nav className="me-auto">
              <Nav.Link href="/EdicoesAnteriores">Edições Anteriores</Nav.Link>
              <Nav.Link href="/galeria">Galeria</Nav.Link>
              <Nav.Link href="/bandas">Artistas e Bandas</Nav.Link>
              <Nav.Link href="/organizacao">Organização e Apoio</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default Header;