import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import image from "../Photos/cartaz2024.jpg";
import { Dialog, DialogContent } from "@mui/material";
import { SiGooglemaps } from "react-icons/si";

//Patrocinios Logo
import ovarmat from "../Photos/Patrocinios/ovarmat.png";
import zeiss from "../Photos/Patrocinios/zeiss.png";
import qualiavelar from "../Photos/Patrocinios/qualiavelar2.png";
import jfavanca from "../Photos/Patrocinios/juntafreguesia_avanca.png";
import lasvegas from "../Photos/Patrocinios/lasvegas.png";
import estudionunes from "../Photos/Patrocinios/estudionunes.png";
import espiraloticas from "../Photos/Patrocinios/espiraloticas.png";
import roteiro from "../Photos/Patrocinios/roteiro.png";
import estarreja from "../Photos/Patrocinios/estarreja.png";

//photos 2024
import p1 from "../Photos/2024/AvancaGare002.png";
import p2 from "../Photos/2024/AvancaGare005.png";
import p3 from "../Photos/2024/AvancaGare006.png";
import p4 from "../Photos/2024/AvancaGare007.png";
import p5 from "../Photos/2024/AvancaGare008.png";
import p6 from "../Photos/2024/AvancaGare009.png";
import Photos from "./Photos";

export default function Home() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const avancagare2024 = [p1, p2, p3, p4, p5, p6];

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const styleSponsor1stRow = { width: "150px", margin: "4px" };
  const styleSponsor2ndRow = { width: "75px", padding: "0", margin: "4px" };

  return (
    <Container>
      <h1>Avanca Gare 2024</h1>
      <h3 style={{ textAlign: "center" }}>24 de Agosto • Clube Caça e Pesca</h3>
      <h4 style={{paddingTop:"15px"}}>Ainda não ouviram falar do Avanca Gare?!</h4>
      <p
        className={expanded ? "full-text" : "max-lines"}
        onClick={toggleExpanded}
      >
        <p>
          Dia 24 de agosto de 2024, o festival apresentou a sua 3.ª edição no
          Clube de Caça e Pesca de Avanca. Como já seria de esperar, foi
          rockalhada até de madrugada! O dia começou com os Sunglasses
          Dixieband, que trouxeram de Almada o que de melhor o jazz tradicional
          tem para nos oferecer. Começaram por tocar pelas ruas de Avanca,
          atraindo os festivaleiros através do som do saxofone, acabando por
          subir a palco para uma última música. Foi assim inaugurada a terceira
          edição do Avanca Gare!
        </p>
        <p>
          Os Van Bloom foram o primeiro comboio a dar entrada na estação palco,
          e não podiam ser de outro sítio que não Fafe, ou melhor, Hollywood.
          Bastaram os primeiros acordes para termos a certeza que nem um
          concerto às 16:00 pode ser calminho quando se trata de um festival de
          rock. Com o seu som alternativo e frenético, os Van Bloom abriram como
          muitos fecham: com o público a arfar.
        </p>
        <p>
          Próxima paragem, Caravananana, que entraram a toda a velocidade. Não
          há sinais vermelhos que os detenham, trazem groove para dar e vender.
          Convidam o público a juntar-se à viagem, de janelas abertas, rádio
          alto e solavancos pelo caminho.
        </p>
        <p>
          Ao fim da tarde, com o sol a pôr-se e de fino na mão, o nosso público
          foi presenteado com a banda que já é da casa! Os Nódoa Negra
          fizeram-se ouvir e, como já é tradição, viraram o festival do avesso.
          As suas músicas inspiradas em expressões populares, como “Cabeça de
          alho xoxo” ou “Batatas com enguias”, fizeram até os festivaleiros mais
          tímidos gritar os refrões. A distância entre o cais e a plataforma foi
          várias vezes testada pelo público, que durante o concerto subiu para
          cima das grades.
        </p>
        <p>
          O nosso comboio noturno foi inaugurada da forma mais bonita que
          qualquer festival poderia ambicionar. Os Javisol sobem a palco e saem
          deixando a plateia mudada. Com uma voz e melodia marcante, a banda
          lisboeta mistura rock com traços de fado. Um momento único na história
          do festival, em que ninguém foi capaz de desviar o olhar. O Avanca
          Gare rendeu-se aos Javisol, que, com um concerto marcante, mostraram
          que há muita música boa escondida no underground.
        </p>
        <p>
          A plataforma tremeu à chegada da próxima paragem. É verdade, reza a
          lenda que o sismo terá sido causado pelos Filhos da Pátria, que
          entraram em palco como entravam os Xutos nos anos 80. Como um shot de
          adrenalina, os tripeiros arrastaram o público para a frente e foi mosh
          do início ao fim. Quem quis ver esta banda de perto não teve outra
          alternativa que não ser levado pela multidão. Numa mistura de animação
          e euforia, os Filhos da Pátria apelam àqueles que têm saudades do
          verdadeiro rock português, mostrando que ele está vivo e de boa saúde.
          Para fechar a noite, a plateia junta-se para receber os anfitriões.
        </p>
        <p>
          {" "}
          Como já é costume, não interessa o quão cansado esteja o público, uma
          coisa é certa: nunca estão cansados para os Cerial Quiller. Num
          concerto emocionante, a voz do vocalista é abafada pelos gritos dos
          fãs, que sabem as letras de cor e salteado. Entre circle e mosh pits,
          era possível ver toda a plateia, em simultâneo e de forma coordenada,
          a levar a cabeça de cima para baixo ao som de solos de guitarra que só
          são autorizados depois da meia-noite. É entre gritos e pedidos de “Só
          mais uma!”, que os Cerial deixam a estação palco, sabendo que chegaram
          à paragem final.{" "}
        </p>
        <p>
          No entanto, a noite não acabou para aqueles que ainda tinham energia
          para gastar… Na estação sunset, quase ao ressoar do último acorde da
          estação palco, a Morra a Dantas Morra começa um Dj Set de fazer
          ressuscitar o David Bowie. Com influências do punk e post-punk, a
          fafense fecha o Avanca Gare revistando os grandes ícones. Fecharam-se
          assim as portas da estação, depois de uma viagem cheia de boa música e
          muita dança.{" "}
        </p>
        <p>
          A equipa Avanca Gare agradece imensamente a todos os que continuam a
          apoiar o festival e incentivam o rock nacional. Um especial obrigado
          aos “Os Benjamins” que proporcionaram os melhores comes e bebes para
          que o nosso público pudesse aguentar a loucura que se passava na pista
          de dança. Muito obrigado!
        </p>
        <p>
          <b>Viva o rock e viva o Avanca Gare!</b><br></br>
          <i>Texto de Joana Viana</i>

        </p>
      </p>
      {Photos(avancagare2024)}
      <h1 className="pb-2">Cartaz</h1>
      <Row className="pb-3" lg={"2"} xs={"1"}>
        <Col>
          <img
            alt=""
            style={{ width: "100%" }}
            src={image}
            loading="lazy"
            onClick={() => handleImageClick(image)}
          />
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            *Clica no cartaz para fazer zoom<br></br>
          </p>
        </Col>
        <Col>
          <iframe
            src="https://open.spotify.com/embed/playlist/1vElABvqtfE1lbUe4VZos7?utm_source=generator"
            style={{
              borderRadius: "12px",
              paddingTop: "2%",
              width: "100%",
              height: "100%",
              minHeight: "500px",
            }}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Col>
      </Row>
      <h1>Apoios e Patrocinios</h1>
      <Col>
        <Row style={{ display: "inline-block" }}>
          <img alt="" style={styleSponsor1stRow} src={ovarmat} loading="lazy" />
          <img alt="" style={styleSponsor1stRow} src={zeiss} loading="lazy" />
          <img
            alt=""
            style={styleSponsor1stRow}
            src={qualiavelar}
            loading="lazy"
          />
          <img
            alt=""
            style={styleSponsor1stRow}
            src={lasvegas}
            loading="lazy"
          />
        </Row>
      </Col>
      <Col>
        <Row style={{ display: "inline-block" }}>
          <img
            alt=""
            style={styleSponsor2ndRow}
            src={estudionunes}
            loading="lazy"
          />
          <img
            alt=""
            style={styleSponsor2ndRow}
            src={espiraloticas}
            loading="lazy"
          />
          <img
            alt=""
            style={styleSponsor2ndRow}
            src={jfavanca}
            loading="lazy"
          />
          <img alt="" style={styleSponsor2ndRow} src={roteiro} loading="lazy" />
          <img
            alt=""
            style={styleSponsor2ndRow}
            src={estarreja}
            loading="lazy"
          />
        </Row>
      </Col>
      <Dialog open={selectedImage !== null} onClose={handleClose}>
        <DialogContent
          style={{
            padding: "0px",
            overflow: "hidden",
          }}
        >
          {selectedImage && (
            <img alt="" src={selectedImage} style={{ width: "100%" }} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}
