import Container from "react-bootstrap/esm/Container";
import React from 'react';
/**
 * Faz display com texto sobre a informação do evento
 */

function Verao() {
    return (
        <div>
            <Container id="info">
                <h1>Agosto 2023</h1>
                <h4 style={{textAlign:'center'}}  className="pt-3">Avanca Gare um festival de bandas de garagem Avanca, Aveiro</h4>
                <p>
                    Realizou-se em Avanca em Aveiro a segunda edição do Avanca Gare, um mini festival de bandas de garagem, cheio de talento, juventude, boa disposição e muito ROCK.
                    Talvez nunca tenham ouvido falar no Avanca Gare, um festival de pretende tirar as bandas emergentes da garagem, dar-lhes um palco e deixa-las voar ao som do rock
                    e punk, num palco ondem podem mostrar todo o seu talento por vezes inexperiência, mas também toda a sua loucura e originalidade, e que faz o rock ser tão genuíno
                    e continuar vivo, em Avanca, está a bombar.</p>
                <p>
                    Os organizadores mostram uma energia fantástica, num espaço que embora pequeno, tem tudo o que os outros festivais têm,
                    desde boa comida e bebida fresca, vários espaços para sentar, dançar ou cantar com as bandas até que as pernas e a voz doa, estamos certos que para o ano
                    será ainda melhor, parabéns Avanca Gare.
                </p>
                <a href="https://ineews.eu/avanca-gare-um-festival-de-bandas-de-garagem/?lang=pt-pt">source</a>

                <div class="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/hjlkl_Q3gjs?si=i8GtSUSLN2z3kjur" title="YouTube video" allowfullscreen></iframe>
                </div>
                <h1>PlayList</h1>
                <iframe title="vinte" className="pb-3" style={{ borderRadius: "12px", paddingTop: '2%', width: '100%', height: '500px' }} src="https://open.spotify.com/embed/playlist/3voyM02CMxiHxT83J7Rcro?utm_source=generator" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Container>
        </div>

    );
}
export default Verao;