import './Cartaz.css'
import React from 'react';
import logo from '../Photos/logo.png'
export default function Cartaz() {
    
    return (
        <div id="cartaz">
            <div>
                <img 
                    alt=""
                    src={logo}
                    id='logo'
                />{' '}
            </div>
        </div>
    );
}