import { Button, Container } from "react-bootstrap";
import React from 'react';
import '../Componentes/App.css'
import cartaz from '../Photos/cartazOvar.jpg'
import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";

export default function Ovar() {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };


    return (
        <>
            <Container alt="Max-width 100%">
                <h1>Dezembro 2023</h1>
                <h4 style={{textAlign:'center'}} className="pt-3">Avanca Gare em Ovar</h4>


                <p>
                Já ouviram falar do Avanca Gare?! Em dezembro de 2023 realizou-se em Ovar a terceira edição do evento, para aqueles que são incapazes de abdicar de um bom festival de rock, mesmo no frio do inverno… Nesta edição trancámos o portão do quintal, metemo-nos no comboio, e siga para o Azulejo Bar! Agora indoors, fizemos questão de encher o palco com bandas que fizessem o público aquecer.
                </p>

<p>
Tudo começou com Jepards, que abriram como quem fecha uma noite. Com o seu rock frenético puseram logo o público a dançar, e foi mosh até ao final. A verdadeira prova de que em Fafe ninguém fanfe… De seguida, já da família Avanca Gare, os Sadhäna mantiveram a tradição de partir a casa toda. Entre trocas de instrumento e crowdsurfings, os tripeiros foram a loucura do público. Em terceiro lugar, os Cerial Quiller são recebidos em alvoroço, provando que basta os anfitriões subirem a palco, que qualquer pessoa volta a ganhar energia. Depois de tantas músicas gritadas pela plateia, moshs malucos e headbangings coordenados, é seguro assegurar que não importa onde vá o Avanca Gare, os fãs de Cerial Quiller estarão sempre a mandar tudo abaixo. Quando o público pensava não aguentar mais, os MÁ VIZINHANÇA fecharam a noite como só o rock mais pesado do Barreiro é capaz de fazer. Trouxeram da margem sul tudo o que de melhor o Rock’n’Roll português tem para oferecer. Acabaram o festival com o chão a tremer e a plateia sem voz de tanto gritar por mais. Passou-se assim uma noite incrível, cheia de boa música e bandas emergentes que mostram ter muito para oferecer. 
</p>

                <p>A organização não tem palavras para agradecer todo o apoio e carinho que tem recebido. Prometemos continuar a trabalhar para levar bandas de garagem aos palcos e trazer edições do Avanca Gare cada vez melhores.</p>

                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.610363724943!2d-8.62747082345924!3d40.85847762863463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23852fc068bcff%3A0xf5b5170b77f55d4!2sAzulejo%20Bar!5e0!3m2!1spt-PT!2spt!4v1699037037477!5m2!1spt-PT!2spt" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div className="d-grid gap-2">
                    <Button size="lg" onClick={() => {
                        handleImageClick(cartaz)
                    }}>
                        Cartaz
                    </Button>
                </div>
                <h1>PlayList</h1>
                <iframe title="playlist" className="pb-3" style={{ borderRadius: "12px", paddingTop: '2%', width: '100%', height: '500px' }} src="https://open.spotify.com/embed/playlist/5DAJfYaNsmWexoZ8xuHtib?utm_source=generator&theme=0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Container>
            <Dialog open={selectedImage !== null} onClose={handleClose} >
                <DialogContent style={{
                    padding: '0px',
                    overflow: 'hidden'
                }} >
                    {selectedImage && (
                        <img alt="" src={selectedImage} style={{ width: '100%' }} />
                    )}
                </DialogContent>
            </Dialog>
        </>);
}
