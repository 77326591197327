import './Componentes/App.css';
import React from 'react';

import Header from './Componentes/Header';
import Footer from './Componentes/Footer';
import Bandas from './Componentes/Bandas';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Ovar from './Componentes/Ovar';
import Cartaz from './Componentes/Cartaz';
import Photos from './Componentes/Photos';
import Home from './Componentes/Home';

//Galeria
import photo2 from './Photos/2.jpg'
import photo3 from './Photos/3.jpg'
import photo4 from './Photos/4.jpg'
import photo6 from './Photos/6.jpg'
import photo7 from './Photos/7.jpg'
import photo8 from './Photos/8.jpg'
import photo9 from './Photos/9.jpg'
import photo11 from './Photos/11.jpg'
import photo12 from './Photos/12.jpg'
import ovar6 from './Photos/Ovar/ovar6.jpg'
import ovar7 from './Photos/Ovar/ovar7.jpg'
import ovar8 from './Photos/Ovar/ovar8.jpg'
import Verao from './Componentes/Verao';
import Organizacao from './Componentes/Organizacao';

function App() {

  const itemData = [
        photo2,
        photo3,
        photo4,
        photo6,
        photo7,
        photo8,
        photo9,
        photo11,
        photo12,
        ovar6,
        ovar8,
        ovar7,
    ]
    

    return (
    <div className="App">
      <div className="main">
            <BrowserRouter>
                <Routes>
                    {/* Common routes with Header and Footer */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Header />
                                <Cartaz />
                                {/* Outlet for nested routes */}
                                <Outlet />
                                <Footer />
                            </>
                        }
                    >
                        <Route path="/" element={<Home />} />
                        <Route path="/EdicoesAnteriores" element={
                            <>
                                <Ovar />
                                <Verao />
                            </>
                        } />
                        <Route path="/bandas" element={<Bandas />} />
                        <Route path="/bandas" element={<Bandas />} />
                        <Route path="/organizacao" element={<Organizacao />} />
                        <Route path="/galeria" element={
                            <>
                                <h1 sx={{
                                    paddingTop: "2%"
                                }}>Galeria</h1>
                                {Photos(itemData)}
                            </>
                        } />
                    </Route>
                    {/* Special route without Header and Footer */}
                </Routes>
            </BrowserRouter>
            {//AlertCopied(alert)
            }
        </div>
    </div>
  );
}

export default App;
