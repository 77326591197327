import Container from 'react-bootstrap/Container';
import BandCard from './BandCard';
import './Bandas.css'
import React from 'react';
import alice from '../Photos/bands/alice.JPG'
import cerial from '../Photos/bands/cerial.jpeg'
import diva from '../Photos/bands/diva.jpg'
import marquise from '../Photos/bands/Marquise.jpg'
import sadhana from '../Photos/bands/sadhana.jpg'
import nodoanegra from '../Photos/bands/NODOANEGRA.JPG'
import mavizinhanca from '../Photos/bands/mavizinhanca.jpg'
import jepards from '../Photos/bands/jepards.jpg'
import javisol from '../Photos/bands/javisol.png'
import filhosdapatria from '../Photos/bands/filhosdapatria.png'
import sunglassesdixieband from '../Photos/bands/sunglassesdixieband.png'
import caravananana from '../Photos/bands/caravananana.png'

//import defaultPhoto from '../Photos/bands/default.jpg'

/**
 * Para cada banda faz o render um cartão com a sua informação.
 * 
 * @param {Array} bands Array de bandas 
 * @returns 
 */
function Bandas() {

    var bands = [
        { instagram: 'alicewdll', photo: alice, name: 'Alice', description: 'Country Punk • Lisboa', confirmation: true },
        { name: 'Cerial Quiller', instagram: 'cerial_quiller', description: 'Rock • Avanca', photo: cerial, youtube: 'https://www.youtube.com/@cerial_quiller', spotify: 'https://open.spotify.com/artist/2vmn5ZNwvELhiAdxVpu3BH' },
        { name: 'Divã', description: 'Post Punk • Amadora', confirmation: true, instagram: 'banda_diva', photo: diva },
        { instagram: 'm.ar.qui.se', youtube: 'https://www.youtube.com/channel/UCrmAbGsZKBAmKPWsYcQ3TDw', name: 'Marquise', spotify: 'https://open.spotify.com/intl-pt/artist/4t5au9fGRP3vpBf6wLPUIu?si=6BAVGpO8QOas8-IZ6FDbbQ', description: 'Rock Alternativo • Porto ', photo: marquise },
        { instagram: 'nodoa.negra', description: 'Punk • Almada', name: 'Nódoa Negra', soundcloud: 'https://soundcloud.com/nodoanegra', youtube: 'https://www.youtube.com/@nodoanegramusic', photo: nodoanegra },
        { name: 'Sadhäna',youtube:'https://www.youtube.com/@sadhana_band', description: 'Punk Rock • Porto', photo: sadhana, spotify: 'https://open.spotify.com/artist/4odryzOyeYDvsndwpSniNK?si=bNd-6odfRA2Yxhk9Pf5AAw', instagram: 'sadhana_band', },
        { name: 'Má Vizinhança', description: 'Hard Rock • Lisboa', photo: mavizinhanca, spotify: 'https://open.spotify.com/intl-pt/artist/6b668P1Ynm1VpC9k4oy4bo?si=_W5MaaFyQi-119mhDUCEZA&nd=1', instagram: 'mavizinhanca', youtube: 'https://www.youtube.com/channel/UCH2yufVBJhJ4r37kC1niAVg' },
        { name: 'Jepards', description: 'Rock Alternativo • Fafe', photo: jepards, instagram: 'jepards_band', youtube: 'https://www.youtube.com/channel/UCDiwbnjLv0bMVQSMOi-Lnsg', spotify: 'https://open.spotify.com/intl-pt/artist/24bD87DnmxOzUG4DrAYsO2?si=ulj_3EsxSXaxgsXD41XZqQ' },
        { name: 'Filhos da Pátria', description: 'Rock • Porto', photo: filhosdapatria, instagram: 'filhosdapatria_band', youtube: 'https://www.youtube.com/@FilhosDaPatriaF.D.P', spotify: 'https://open.spotify.com/intl-pt/artist/6tWxYJqanQTaAvocLb4Cex?si=r0ZxJBLBQ8SIetCZVyRE7Q' },
        { name: 'Javisol', description: 'Rock • Lisboa', photo: javisol, instagram: 'palmasparajavisol', youtube: 'https://www.youtube.com/@javisolpt', spotify: 'https://open.spotify.com/intl-pt/artist/15W3dfnmb1LSLdfIzrTIy8?si=HKJKvpH9QyuqxYcwLoBL_A' },
        { name: 'Sunglasses Dixieband', description: 'Jazz tradicional • Almada', photo: sunglassesdixieband, instagram: 'sunglasses.dixieband' },
        { name: 'Caravananana', description: 'Blues Rock • Setúbal', photo: caravananana, instagram: 'caravananana', youtube:'https://www.youtube.com/@caravananana1312', spotify:'https://open.spotify.com/intl-pt/artist/3yKVDUwW3zW4xuJ2HprEIV?si=deNGwPxDQ96-IHY_PhXIAw' },
    ]

    return (
        <>

            {
                bands.length > 0 &&
                <Container fluid className='p-5' id="bands">
                    <h1 className='mb-5'>Artistas e Bandas</h1>
                    <div id='grid'>
                        {bands.map(band =>
                            BandCard(band)
                        )}
                    </div>
                </Container >
            }
        </>
    );
}

export default Bandas;