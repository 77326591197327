import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import { HiOutlineMail } from 'react-icons/hi';
import { BsInstagram, BsSpotify, BsYoutube } from 'react-icons/bs';
import './Footer.css';

/**
 * Footer da aplicação, com o contacto, uma mensagem
 * e um botão com redirect para o paypal para contribuições
 */
function Footer() {
    return (
        <>
            <div className='footer'>
                <Container className='pt-5 pb-3'>
                    <Card.Title className='pb-3'>
                        Este projeto é independente.<br />
                        Qualquer tipo de ajuda faz a diferença.
                        Obrigado por apoiarem o Rock Português.<br />
                    </Card.Title>
                    <Card.Text class="col text-center">
                        <HiOutlineMail />geral@avancagare.pt<br />
                        <a rel="noopener noreferrer" href="https://open.spotify.com/user/31byr5ckokwcbpepvp5yppwsderi?si=15b2a92751144b3c" target="_blank" id="icon">
                            <BsSpotify size={30}></BsSpotify>
                        </a>
                        <a rel="noopener noreferrer" href='http://www.instagram.com/avancagare' target='_blank' id="icon">
                            <BsInstagram size={30} className='m-3'></BsInstagram>
                        </a>
                        <a rel="noopener noreferrer" href='https://www.youtube.com/@AvancaGare' target="_blank" id="icon">
                            <BsYoutube size={35} />
                        </a>
                    </Card.Text>
                </Container>
            </div>
        </>
    );
}


export default Footer;