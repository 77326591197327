import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import fotoOrganizacao from '../Photos/organizacao.jpg'
import { IoCopy } from "react-icons/io5";
import { Alert, Button, Collapse } from "@mui/material";


export default function Organizacao() {
    const [show, setShow] = useState(false);
    const numero = 936401227;

    const delay = ms => new Promise(res => setTimeout(res, ms));


    return (
        <>
            <Container>
                <h1>Organização</h1>

                <Row className="pb-3" lg={'2'} xs={'1'}>
                    <Col>
                        <h2>Quem somos?</h2>
                        <p>
                            O Avanca Gare é organizado por um grupo de amigos de variadas áreas da arte.
                            Jovens dinâmicos, estudantes e ex-estudantes da Jobra, ESTC e ESMAE, multi disciplinados
                            com uma paixão enorme pelo projeto e por fazer a diferença na cultura.
                            Com fundos e apoios bastante limitados apenas
                            continua a ser possível fazer mais edições porque estas pessoas realmente se dão
                            ao projeto e percebem o propósito do mesmo.
                        </p>
                        <h2>Missão do Projeto</h2>
                        <p>
                            Este projeto conta com a missão de apelar ao consumo de arte portuguesa nas suas mais variadas formas,
                            aumentando o valor da arte e dos artistas nacionais. Fazemos por, dentro do recinto do festival, criar
                            locais para, por exemplo, venda de quadros e obras feitas por artistas independentes. O processo de seleção
                            das bandas faz-se por ir buscar projetos emergentes que procuram alcançar novos horizontes mas são recusadas
                            na maioria dos locais. Procuramos criar um maior valor na arte que existe e que é produzida em Portugal.
                        </p>
                    </Col>
                    <Col>
                        <Image style={{ width: '100%' }} src={fotoOrganizacao}></Image>
                    </Col>
                </Row>

                <h1>Apoios</h1>

                <h2>Quem nos Apoia?</h2>
                <p>
                    Neste momento o Avanca Gare conta com o apoio da companhia KOPINXAS.
                    Os KOPINXAS são um coletivo artístico de âmbito teatral, com sede em
                    Avanca, marcado pelo companheirismo e a vontade de criar, valores que se
                    alinham em muito com o que o Avanca Gare aspira ser. A associação tem tido
                    um papel fundamental, não só no apoio ao projeto, como também na promoção
                    de eventos culturais em Avanca, incentivando à descentralização da cultura.</p>
                <p>
                    Na edição de inverno do Avanca Gare, em dezembro de 2023, contámos com o
                    patrocínio de Marta Dias Cabeleireiros, People, Triângulos, Mar Cheio, 1001
                    Prendas, Dão Duarte, Casa Lopes, Clínica Egas Moniz, Tâmega, Papel e Arte,
                    OvarMat, Quali Avelar, César, Roteiro, Matilde, Veterinário, Espiral Ópticas.
                </p>
                <p>
                    Sem estas entidades, o Avanca Gare não podia acontecer. Um sincero
                    obrigada a todos os que acreditam no projeto.
                </p>
                <h2>Quero Apoiar o projeto</h2>
                <p>
                    Se, tal como nós, vês algo de especial no Avanca Gare e queres apoiar este
                    projeto, podes enviar a tua contribuição através de 936 401 227. Qualquer ajuda faz a
                    diferença e pode ser decisiva, já que se trata de um evento criado do zero e
                    sem financiamento. Dependemos de contribuições para fazer o Avanca Gare
                    acontecer. Obrigada por acreditarem em nós e apoiarem o rock underground!
                </p>
                <p>
                    Se és uma empresa e queres apoiar o Avanca Gare, podes enviar um email
                    para geral@avancagare.pt , qualquer contribuição é valiosa. Devido
                    à associação KOPINXAS, que apoia o projeto, somos capazes de passar fatura
                    com número de contribuinte. Muito obrigada a todas as empresas que
                    apoiaram o projeto até agora!

                </p>
               
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<IoCopy />}
                    onClick={async () => {
                        navigator.clipboard.writeText(numero)
                        setShow(true)
                        await delay(2000)
                        setShow(false)
                    }}
                >
                    {numero}
                </Button>
                {NewAlert(show)}
                <p style={{ textAlign: 'center', fontStyle: 'italic' }} className="mt-3">clica no botão para copiar o número</p>

                <div class="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/_KPCEXDTidw?si=8I4s33trqlb9Baou" title="YouTube video" allowfullscreen className="pt-3 pb-4"></iframe>
                </div>

            </Container>
        </>
    )
}

function NewAlert(show) {
    return (
        <Collapse in={show}>
        <Alert variant="filled" severity="success" className="m-3">Numero copiado</Alert>
        </Collapse>
    );
}